@font-face {
    font-family: "msb";   /*Can be any text*/
    src: local("MYRIADPRO-SEMIBOLD"),
        url("assets/fonts/MYRIADPRO-SEMIBOLD.OTF") format("truetype");
    }
@font-face {
    font-family: "mc";   /*Can be any text*/
    src: local("MYRIADPRO-CONDIT"),
        url("assets/fonts/MYRIADPRO-REGULAR.OTF") format("truetype");
    }
@font-face {
    font-family: "ml";   /*Can be any text*/
    src: local("MYRIADPRO-Light"),
        url("assets/fonts/MyriadPro-Light.otf") format("truetype");
    }

@font-face {
    font-family: "msl";   /*Can be any text*/
    src: local("MYRIADPRO-Light"),
        url("assets/fonts/MYRIADPRO-SEMIBOLDIT.OTF") format("truetype");
    }

@font-face {
    font-family: "mb";   /*Can be any text*/
    src: local("MYRIADPRO-BOLD"),
        url("assets/fonts/MYRIADPRO-BOLD.OTF") format("truetype");
    }

@font-face {
    font-family: "ns";   /*Can be any text*/
    src: local("Planet_NS_Expanded"),
        url("assets/fonts/Planet_NS_Expanded.ttf") format("truetype");
    }


* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    }
    
*::-webkit-scrollbar {
display: none; /* Chrome, Safari, and Opera */
}

.router ul { 
    padding: 0; 
    list-style: none; 
    display: table;
    text-align: center;
  }
.router li { 
    display: table-cell; 
    width: 50px !important;
  }
.router a,p {
    color: #77736b;
    text-decoration: none;
    font: 14px/1.4 'Open Sans', arial, sans-serif;
    font-weight: 500;
    display: inline-block;
    padding: 5px 10px;
    position: relative;
    transition: font-weight 0.2s ease-in-out;
    margin: 0px 5px;
  }

.router p:after{
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    background: #ff9900;
    width: 100%;
}
.router a:hover{
    color: #545049;
    font-weight: 600;
    
  }
.router a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #a8a297;
    transition: width 0.1s ease 0s, left 0.1s ease 0s;
    width: 0;
    

  }
.router a:hover:after { 
    width: 100%; 
    left: 0; 

  }


  .addpost button {
    font: 14px/1.4 'Open Sans', arial, sans-serif;
    font-weight: 700;
      padding: 5px 10px;
      border: 1px solid #CAC2B6;
      cursor: pointer;
      color: #545049;
      border-radius: 10px;
      display: inline-block;
      overflow: hidden;
      transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
  }

.addpost button::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0;
}

.addpost button {
	background: white;
	color: #545049;
	transition: 0.4s;
	transform: perspective(1px) translateZ(0);
}

.addpost button:hover {
	color: white;
	box-shadow: 0 0 0 10px rgba(250, 250, 250, 0.1)
}

.addpost button::before {
	clip-path: circle(0.5% at 50% 50%);
	background: #f90;
	transition: 0.4s;
	opacity: 1;
}

.addpost button:hover::before {
	clip-path: circle(100% at 50% 50%);
}

.account_window{
    transition: all 0.2s ease-in-out;
}



.account_window{
    transition: all 0.2s ease-in-out;
}

.viewpost{
    transition: all 0.25s ease-in-out;
}

.register p {
    color: #f90;
    font: 18px/1.4 'Open Sans', arial, sans-serif;
    text-decoration: none;
    display: inline-block;
    font-weight: 700;
    position: relative;
    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.8) 30%, #000 50%, rgba(0,0,0,.6) 70%);
    -webkit-mask-size: 200%;
    animation: shine 2s linear infinite;
  }
  
  @keyframes shine {
    from { -webkit-mask-position: 150%; }
    to { -webkit-mask-position: -50%; }
  }


.login_submit{
    background-color: rgb(255, 185, 79);
}


.login_submit p{
    color: white;
}
.login_submit:hover{
    background-color: white;
}

.login_submit:hover p{
    color: rgb(255, 185, 79);
}


.loader {
    border: 5px solid rgba(0,0,0,.2);
    border-top: 5px solid #a8a297;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }